import type { Project } from "~/types/api"
import type { NavTab } from "~/types/props"

export function useProjectTabs(project: Ref<Project | undefined>) {
  const { $toShortId } = useNuxtApp()

  const tabs = computed<NavTab[]>(() => {
    if (!project.value) return []
    const projectId = $toShortId(project.value.id)
    return [
      {
        to: `/projects/${projectId}/details`,
        label: "Details",
      },
      {
        to: `/projects/${projectId}/scenarios`,
        label: "Scenarios",
        count: project.value.scenarioCount,
      },
      {
        to: `/projects/${projectId}/value-maps`,
        label: "Value Maps",
        count: project.value.loadProfileCount,
      },
      {
        to: `/projects/${projectId}/reports`,
        label: "Reports",
      },
      {
        to: `/projects/${projectId}/detail-view`,
        label: "Detail View",
        hidden: !hasLicenceFlag("closeUpView"),
      },
      {
        to: `/projects/${projectId}/dashboards`,
        label: "Visualisation Dashboards",
      },
      {
        to: `/projects/${projectId}/external-users`,
        label: "External Users",
        hidden: !hasLicenceFlag("externalUsers"),
      },
    ]
  })

  return tabs
}
